<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>供应链管理</template>
      <template v-slot:secondMenu>失败记录详情</template>
    </breadcrumb-nav>
    <el-card>
      <el-form :inline="true">
        <el-form-item label="商品名称" prop="name">
          <el-input  v-model="listQuery.name" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getFailedList">查询</el-button>
          <el-button type="success" @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :data="list" border stripe style="margin-top: 10px;">
        <el-table-column width="30" type="index" label="#"></el-table-column>
        <el-table-column label="同步类型" width="200" prop="name">
          <template slot-scope="scope">{{ scope.row.failedType | failedTypeLabel }}</template>
        </el-table-column>
        <el-table-column width="200" label="供应商记录id" prop="sourceId">
        </el-table-column>
        <el-table-column width="200" label="名称" prop="name">
        </el-table-column>
        <el-table-column label="失败原因" prop="reason">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { getFailedDetailRequest } from '../../../network/supply'
import BreadcrumbNav from '../../common/BreadcrumbNav'
export default {
  name: 'FailedDetail',
  components: {
    BreadcrumbNav
  },
  filters: {
    failedTypeLabel (value) {
      switch (value) {
        case 1:
          return '全量同步品牌'
        case 2:
          return '增量同步品牌'
        case 3:
          return '全量同步分类'
        case 4:
          return '增量同步分类'
        case 5:
          return '全量同步商品'
        case 6:
          return '增量同步商品'
      }
    }
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10,
        recordId: 0,
        supplierId: 0,
        name: ''
      },
      list: [],
      total: 0
    }
  },
  created () {
    this.listQuery.recordId = this.$route.query.recordId
    this.listQuery.supplierId = this.$route.query.supplierId
    this.getFailedList()
  },
  methods: {
    getFailedList () {
      getFailedDetailRequest(this.listQuery).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取同步记录失败', 'error')
        }
        this.list = res.data.data.list
        this.total = res.data.data.total
        console.log('--------this.failed list:', res.data.data.list)
      })
    },
    goBack () {
      this.$router.back()
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getFailedList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getFailedList()
    }
  }
}
</script>

<style scoped>

</style>
